import React from 'react';
import './achievements.css';
import image2 from '../../assets/image2.png';
import Pic1 from '../../assets/pic2.jpg';
import Pic2 from '../../assets/pic3.jpg';
import Pic3 from '../../assets/pic4.jpg';
import Pic4 from '../../assets/pic16.png';

import { Link } from "react-router-dom";
// import imagebg from '../../assets/imagebg.jpg';

const Achievements = () => {
  return (
    <div className="background1">
    <div className="co__achievements">
      <div className="co__achievements_content">
        <h1>Achievements</h1>
        <p>Hon. Chidera Odo has made significant contributions to our state during his career
           as a Special Adviser to the Enugu State Governor on Foreign Affairs and SLS to 
           Sen. Chimaroke Nnamani (Enugu-East Senatorial Zone). <br /> <br />
           
           He is also the Chairman of the Following boards; <br />
           - Colliery Guest House, GRA, Enugu. <br />
           - Otomoha Guest House, Ukehe Enugu. <br /> <br />
          <Link to="/investments">
              See more...
          </Link>
        </p>
      </div>
      <div className="co__achievements_img">
        <img src={image2} alt="Omeogo" />
      </div>
    </div>
    <div className="co__images">
      <div className="co_image">
        <img src={Pic1} alt="hotel" />
      </div>

      <div className="co_image">
        <img src={Pic2} alt="hotel" />
      </div>

      <div className="co_image">
        <img src={Pic3} alt="hotel" />
      </div>

      <div className="co_image">
        <img src={Pic4} alt="hotel" />
      </div>
    </div>
    </div>
  )
}

export default Achievements