import React from 'react';
import './investmentsin.css';
import { Contact } from '../../container';

import investment1 from '../../assets/investment1.png'
import investment2 from '../../assets/investment2.png'
import investment3 from '../../assets/investment3.png'
import investment4 from '../../assets/investment4.png'
import investment5 from '../../assets/investment5.png'
import investment6 from '../../assets/investment6.png'

const Investmentsin = () => {
  return (
    <>
    <div className="investmentsin">
        <h1>Investments</h1>
        <p>He is the Chairman of the following Boards</p>
        <div className="investmentsin_bg">
            <div className="investmentsin__content">
                <div className="investmentsin__content_img">
                    <img src={investment1} alt="investment" />
                </div>
                <div className="investmentsin__content_p">
                    <p>Mezue & Chinuo Ltd (Trade & general merchandise)</p>
                </div>
            </div>

            <div className="investmentsin__content">
                <div className="investmentsin__content_img">
                    <img src={investment2} alt="investment" />
                </div>
                <div className="investmentsin__content_p">
                    <p>Chimamanda & Co Ltd (Production of Soaps and Sanitary Agents)</p>
                </div>
            </div>

            <div className="investmentsin__content">
                <div className="investmentsin__content_img">
                    <img src={investment3} alt="investment" />
                </div>
                <div className="investmentsin__content_p">
                    <p>Glories Properties (Building, Construction, Estate, Realtors)</p>
                </div>
            </div>

            <div className="investmentsin__content">
                <div className="investmentsin__content_img">
                    <img src={investment4} alt="investment" />
                </div>
                <div className="investmentsin__content_p">
                    <p>Colliery Guest House, GRA, Enugu.</p>
                </div>
            </div>

            <div className="investmentsin__content">
                <div className="investmentsin__content_img">
                    <img src={investment5} alt="investment" />
                </div>
                <div className="investmentsin__content_p">
                    <p>High Inn Hotel, Abakaliki</p>
                </div>
            </div>

            <div className="investmentsin__content">
                <div className="investmentsin__content_img">
                    <img src={investment6} alt="investment" />
                </div>
                <div className="investmentsin__content_p">
                    <p>Otomoha Guest House, Ukehe, Enugu State.</p>
                </div>
            </div>
        </div>
    </div>
    <Contact />
    </>
  )
}

export default Investmentsin