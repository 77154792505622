import { Navbar, Footer } from '../components';
import { Contact, Ebeanoin } from '../container';

const Ebeano = () => {
  return (
    <div>
        <div className="ebeano">
          <Navbar />
          <Ebeanoin />
          <Contact />
          <Footer />
        </div>
    </div>
  )
}

export default Ebeano