import React from 'react';
import './ebeanoin.css';

import Ebeano from '../../assets/ebeano.png';
import Ebeano1 from '../../assets/ebeano1.png';

const Ebeanoin = () => {
  return (
    <div className="ebeanoin">
        <div className="ebeanoin__bg">
            <h1>Ebeano Political Family</h1>
            <div className="ebeanoin__img">
                <img src={Ebeano} alt="ebeano" />
                <img src={Ebeano1} alt="ebeano" />
            </div>
            <div className="ebeanoin__text">
                <h2>Hon. Chidera Odo is a dedicated Member of the ebeano Political Family, believes firmly in the Philosophy of Dr. Chimaroke Nnamani and is currently the Chairman, ebeano Volunteer Movement for Peter Mbah</h2>
                <p><a href="http://www.ebeano.org" target='_blank' rel="noreferrer">Click here to know more about ebeano Political Family and Philosophy</a></p>
            </div>
        </div>
    </div>
  )
}

export default Ebeanoin