import { Navbar, Footer } from '../components';
import { Investmentsin } from '../container';

const Investments = () => {
  return (
    <div>
        <div className="investments">
          <Navbar />
          <Investmentsin />
          <Footer />
        </div>
    </div>
  )
}

export default Investments