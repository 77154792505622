import React from 'react';
import './politicsin.css';
import { Contact } from '../../container';
import Omeogo3 from '../../assets/omeogo3.png'

const Politicsin = () => {
  return (
    <>
    <div className="bg">
      <div className="politicsin__bg">
        <div className="politicsin__img">
          <img src={Omeogo3} alt="Omeogo" />
        </div>
        <div className="politicsin__content">
          <h1>Political Achievements</h1>
          <p>Secretary PDP ward 4 - 2003 - 2006</p>
          <p>Supervisor for health, Igbo-Etiti East Development Center - 2006-2007</p>
          <p>State Administrative Secretary People for Democratic Change. 2011</p>
          <p>Executive Assistant to the Governor His Excellency RT Hon Ifeanyi Ugwuanyi 2015</p>
          <p>Secretary New Haven Market Investment Authority. 2017</p>
          <p>Councillor ward 4/Deputy Leader Igbo-Etiti Legislative Council -2019</p>
          <p>Special Assistant to His Excellency RT Hon Ifeanyi Ugwuanyi 2022-2023</p>
          <p>Senior Legislative Aide to Sen. Dr. Chimaroke Nnamani <i>MD FACOG 2019</i> till date</p>
        </div>
      </div>
    </div>
      <Contact />
    </>
  )
}

export default Politicsin