import { Navbar, Footer } from '../components';
import { Politicsin } from '../container';

const Politics = () => {
  return (
    <div>
        <div className="Politics">
          <Navbar />
          <Politicsin />
          <Footer />
        </div>
    </div>
  )
}

export default Politics