import React from 'react';
import './journey.css';
import Ebeano from '../../assets/omeogo1.png'

import { Link } from "react-router-dom"

const Journey = () => {
  return (
    <div className="co__journey">
      <div className="co__journey_body">
        <div className="co__journey_body_img">
          <img src={Ebeano} alt="ebeano" />
        </div>
        <div className="co__journey_body_content">
          <h1>Political Journey</h1>
          <p>Hon. Chidera Odo is a committed member of the ebeano Political Family. He is the Chairman Ebeano Volunteer Movement for Peter Mbah.
            He has served under the umbrella of the People's Demoratic Party (PDP) till date.
            He has served as;
          </p> 
          <ul>
            <li>Special Assistant to His Excellency, Rt Hon. Ifeanyi Ugwuanyi 2022-2023</li>
            <li>Senior Legislative Aide to Sen. Dr. Chimaroke Nnamani MD FACOG 2019 - Date</li>
            <li>Councillor Ward 4/ Deputy Leader Igbo-Etiti Legislative Council 2019</li>
          </ul><br />
          <p>
            <Link to="/politics">
              See More...
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Journey