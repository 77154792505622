import React from 'react';
import'./galleryin.css';

import pic1 from '../../assets/pic1.jpg'
import pic2 from '../../assets/pic2.png'
import pic3 from '../../assets/pic3.png'
import pic4 from '../../assets/pic4.png'
import pic5 from '../../assets/pic5.png'
import pic6 from '../../assets/pic6.png'
import pic7 from '../../assets/pic7.png'
import pic8 from '../../assets/pic8.png'
import pic9 from '../../assets/pic9.png'
import pic10 from '../../assets/pic10.png';
import pic11 from '../../assets/pic11.png';
import pic12 from '../../assets/pic15.png';
import pic13 from '../../assets/pic13.png';
import pic14 from '../../assets/pic14.png';
import pic15 from '../../assets/pic15.png';

const Galleryin = () => {
  return (
    <div className="gallery_bg">
      <h1>--- Gallery ---</h1>
      <div className="co__galleryin">

        <div className="co__galleryin_img">
          <img src={pic1} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic2} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic3} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic4} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic5} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic6} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic7} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic8} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic9} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic10} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic11} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic12} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic13} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic14} alt="Omeogo" />
        </div>

        <div className="co__galleryin_img">
          <img src={pic15} alt="Omeogo" />
        </div>

      </div>
    </div>
  )
}

export default Galleryin