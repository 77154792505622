import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className="co__footer">
      <p> &copy; Chidera Odo 2023 | Designed by Hoppi Tech</p> 
    </div>
  )
}

export default Footer