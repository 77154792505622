import React from 'react';
import './button.css';

const Button = () => {
  return (
    <div className="_button">
        <div className="button">
            <a href="#top"><button >^</button></a>
        </div>
    </div>
  )
}

export default Button