import React from 'react';
import './meet.css';

const Meet = () => {
  return (
    <div className="co__meet">
      <div className="co__meet_content">
        <h1>Meet Chidera Odo</h1>
        <p>Hon. Chidera is a native of Ukehe in Igbo Etiti LGA of Enugu State. <br />
          He started his Educational journey at Orinandu Secondary School, Ukehe were he obtained his SSCE in 2003.
          He went further to obtain a National DIploma in Mass Communication from the Institute of Management Science and Technology in 2005,
          In his insatiable search for knowledge, he obtained a NCE in English/Political Science  from the Enugu State College of Education Technical in 2015.
          He spent most of his life in Enugu, growing and observing the Livelihood and cultural Heritage of the State, which makes him Dedicated to the Prosperity of Enugu State.
           He has acquired useful experiences that eventually served as the 
           cornerstone of his political journey 
        </p>
      </div>
    </div>
  )
}

export default Meet