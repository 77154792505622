import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import Home from "./routes/Home";
import Politics from "./routes/Politics";
import Investments from "./routes/Investments";
import Gallery from "./routes/Gallery";
import Ebeano from "./routes/Ebeano";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/politics" element={<Politics />} />
          <Route path="/investments" element={<Investments />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/ebeano" element={<Ebeano />} />
        </Routes>
      </BrowserRouter>
      
      
    </div>
  )
}

export default App