import React from 'react'
import './header.css'
// import Enugubg from '../../assets/enugubg.png'
import Image1 from '../../assets/omeogo.png'

const Header = () => {
  return (
    <div className="Header_bg background" id="top">
        <div className="co__header">
            <div className="co__header_img">
                <img src={Image1} alt="Chidera" />
            </div>
            <div className="co__header_content">
                <h1>Hon. Chidera Odo</h1>
                <hr />
                <p>
                    You are welcome to Chidera Odo's official website, 
                    where you can find out more about how he is fighting for a better,
                    more prosperous Enugu State. Chidera Odo is committed to having a 
                    good effect on our beloved state with steadfast dedication and a 
                    clear vision.
                </p>
                <p>
                    He believes strongly that he can build a future where there is development, harmony, and 
                    opportunity for everybody.
                    Learn more about the Chidera Odo's history, political career, and 
                    the urgent challenges he wants to solve by exploring the website. 
                    He is excited for your participation and support because he really believe in the power of community.

                </p>
            </div>
        </div>
    </div>
  )
}

export default Header