import { Navbar, Footer } from '../components';
import { Galleryin, Contact } from '../container';

const Gallery = () => {
  return (
    <div>
        <div className="gallery">
          <Navbar />
          <Galleryin />
          <Contact />
          <Footer />
        </div>
    </div>
  )
}

export default Gallery